<template>
  <AppHeader style="z-index: 2300;" class="subnav"> </AppHeader>
  <!-- <AppHeaderSticky /> -->
  <el-dialog
      style="
        border-radius: 20px;
        width: 23%;
        height: 49%;
        position: relative;
      "
      @close="closeTime"
      :show-close="false"
      destroy-on-close="true"
      v-model="isVisible"
  >
    <IsLogin style="position: absolute; left: -15%;top: 5%; border-radius: 20px;" />
  </el-dialog>
  <slot />
  <AppFooter />
</template>
<script setup>
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import IsLogin from "@/components/isLogin.vue";
import emitter from "@/utils/eventBus";
import {ref} from "vue";
let isVisible = ref(false); // 是否显示登录窗口
emitter.on("openLogin", (Fn) => {
  if (Fn.openLogin) {
    isVisible.value = true;
  }
});
const closeTime = () => {
  emitter.emit("closeTimer",{closeTime : true})
}
</script>
<style lang="less" scoped>
.subnav {
  position: fixed;
  top: 0;
  width: 1920px;
  height: 70px;
  z-index: 101;
  left: 50%;
  transform: translateX(-50%);
   //opacity: 0.29;
  background-color: rgba(#000, 0.5);
}
</style>
